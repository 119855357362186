import React, { useState, useEffect } from 'react'
import dynamic from '@next-tools/dynamic'
import loadable from '@loadable/component'

import '../category-carousel.scss'
import SliderArrowButton from '../../controls/slider-arrow-button'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { StaticImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'

const ChassisCarousel = () => {
  const images = [
    '/new/images/product/van/chassis/DARC_VAN_Chassis_1.jpeg',
    '/new/images/product/van/chassis/DARC_VAN_Chassis_2.jpeg',
    '/new/images/product/van/chassis/DARC_VAN_Chassis_3.jpeg',
    '/new/images/product/van/chassis/DARC_VAN_Chassis_4.jpeg',
    '/new/images/product/van/chassis/DARC_VAN_Chassis_5.jpeg',
    '/new/images/product/van/chassis/DARC_VAN_Chassis_6.jpeg',
    '/new/images/product/van/chassis/DARC_VAN_Chassis_7.jpeg',
  ]
  return (
    <Carousel
      className="interior-carousel category-carousel"
      autoPlay={true}
      infiniteLoop={true}
      interval={4000}
      transitionTime={1000}
      showThumbs={false}
      showIndicators={false}
      showArrows={false}
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        images.length > 1 && <SliderArrowButton onClick={onClickHandler} />
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        images.length > 1 && (
          <SliderArrowButton right onClick={onClickHandler} />
        )
      }
    >
      <div>
        <img
          alt="chassis slider image"
          src="/new/images/product/van/chassis/DARC_VAN_Chassis_1.jpeg"
        />
      </div>
      <div>
        <img
          alt="chassis slider image"
          src="/new/images/product/van/chassis/DARC_VAN_Chassis_2.jpeg"
        />
      </div>
      <div>
        <img
          alt="chassis slider image"
          src="/new/images/product/van/chassis/DARC_VAN_Chassis_3.jpeg"
        />
      </div>
      <div>
        <img
          alt="chassis slider image"
          src="/new/images/product/van/chassis/DARC_VAN_Chassis_4.jpeg"
        />
      </div>
      <div>
        <img
          alt="chassis slider image"
          src="/new/images/product/van/chassis/DARC_VAN_Chassis_5.jpeg"
        />
      </div>
      <div>
        <img
          alt="chassis slider image"
          src="/new/images/product/van/chassis/DARC_VAN_Chassis_6.jpeg"
        />
      </div>
      <div>
        <img
          alt="chassis slider image"
          src="/new/images/product/van/chassis/DARC_VAN_Chassis_7.jpeg"
        />
      </div>
    </Carousel>
  )
}

export default ChassisCarousel
