import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

import Header from '../components/header/header'
import ProductBox from '../components/product/product-box'
import Category from '../components/category/category'
import Contact from '../components/contact/contact'
import Footer from '../components/layout/footer'

import ExteriorCarousel from '../components/carousel/exterior-carousel'
import InteriorCarousel from '../components/carousel/van/interior-carousel'
import ChassisCarousel from '../components/carousel/van/chassis-carousel'

import SpecsTable from '../components/table/specs-table'
import Container from '../components/layout/container'
import HeaderCarousel from '../components/carousel/header-carousel'
import CookieBox from '../components/cookie/cookie-box'
import '../components/pages/mono.scss'
import { useTranslation } from 'react-i18next'
import FlaresCarousel from '../components/carousel/rig/flares-carousel'
import RigCarousel from '../components/carousel/rig/rig-carousel'
import FlareRingsCarousel from '../components/carousel/rig/flare-rings-carousel'
import SnorkelCarousel from '../components/carousel/rig/snorkel-carousel'
import GuardCarousel from '../components/carousel/rig/guard-carousel'
import VanCarousel from '../components/carousel/van/van-carousel'
import VanBrochure from '../components/brochure/van-brochure'
import TopCarousel from '../components/carousel/van/top-carousel'

const VanPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>DARC-EXP | VAN</title>
        <body className="product-page" />
      </Helmet>
      <div style={{ background: '#151510' }} className="rig-page">
        <Header />
        <CookieBox />
        <TopCarousel />
        <div className="categories-container">
          <Category
            label="Van"
            id="concept"
            name={t('vanPage.concept.title')}
            desc={
              <>
                <p>{t('vanPage.concept.body.p1')}</p>
              </>
            }
          />
          <VanCarousel />
          <Category
            label="Van"
            id="chassis"
            name={t('vanPage.chassis.title')}
            desc={
              <>
                <p>{t('vanPage.chassis.body.p1')}</p>
                <p>{t('vanPage.chassis.body.p2')}</p>
                <p>{t('vanPage.chassis.body.p3')}</p>
                <p>{t('vanPage.chassis.body.p4')}</p>
              </>
            }
          />
          <ChassisCarousel />

          <Category
            label="Van"
            id="interior"
            name={t('vanPage.interior.title')}
            desc={
              <>
                <p>{t('vanPage.interior.body.p1')}</p>
                <p>{t('vanPage.interior.body.p2')}</p>
                <p>{t('vanPage.interior.body.p3')}</p>
                <p>{t('vanPage.interior.body.p4')}</p>
              </>
            }
          />
          <InteriorCarousel />
        </div>
        <VanBrochure />
        <Contact />
        <Footer />
      </div>
    </>
  )
}

export default VanPage
