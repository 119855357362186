import React from 'react'
import '../category-carousel.scss'
import SliderArrowButton from '../../controls/slider-arrow-button'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

const TopCarousel = () => {
  return (
    <Carousel
      className="header-carousel category-carousel"
      autoPlay={true}
      infiniteLoop={true}
      interval={4000}
      transitionTime={1000}
      showThumbs={false}
      showIndicators={false}
      showArrows={false}
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) => (
        <SliderArrowButton onClick={onClickHandler} />
      )}
      renderArrowNext={(onClickHandler, hasNext, label) => (
        <SliderArrowButton right onClick={onClickHandler} />
      )}
    >
      <div>
        <img
          alt="Van carousel image"
          src="/new/images/product/van/top_carousel/DARC_VAN_Top_Slider_1.jpeg"
        />
      </div>
      <div>
        <img
          alt="Van carousel image"
          src="/new/images/product/van/top_carousel/DARC_VAN_Top_Slider_2.jpeg"
        />
      </div>

      <div>
        <img
          alt="Van carousel image"
          src="/new/images/product/van/top_carousel/DARC_VAN_Top_Slider_3.jpeg"
        />
      </div>

      <div>
        <img
          alt="Van carousel image"
          src="/new/images/product/van/top_carousel/DARC_VAN_Top_Slider_4.jpeg"
        />
      </div>

      <div>
        <img
          alt="Van carousel image"
          src="/new/images/product/van/top_carousel/DARC_VAN_Top_Slider_5.jpeg"
        />
      </div>
    </Carousel>
  )
}

export default TopCarousel
