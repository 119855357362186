import React, { useState, useEffect } from 'react'

import '../category-carousel.scss'
import SliderArrowButton from '../../controls/slider-arrow-button'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

const VanCarousel = () => {
  return (
    <Carousel
      className="header-carousel category-carousel"
      autoPlay={true}
      infiniteLoop={true}
      interval={4000}
      transitionTime={1000}
      showThumbs={false}
      showIndicators={false}
      showArrows={false}
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) => (
        <SliderArrowButton onClick={onClickHandler} />
      )}
      renderArrowNext={(onClickHandler, hasNext, label) => (
        <SliderArrowButton right onClick={onClickHandler} />
      )}
    >
      <div>
        <img alt="Van carousel image" src="/van/main/VAN_Slider_Main_1.jpg" />
      </div>
      <div>
        <img alt="Van carousel image" src="/van/main/VAN_Slider_Main_2.jpg" />
      </div>

      <div>
        <img alt="Van carousel image" src="/van/main/VAN_Slider_Main_3.jpg" />
      </div>

      <div>
        <img alt="Van carousel image" src="/van/main/VAN_Slider_Main_4.jpg" />
      </div>

      <div>
        <img alt="Van carousel image" src="/van/main/VAN_Slider_Main_5.jpg" />
      </div>

      <div>
        <img alt="Van carousel image" src="/van/main/VAN_Slider_Main_6.jpg" />
      </div>

      <div>
        <img alt="Van carousel image" src="/van/main/VAN_Slider_Main_7.jpg" />
      </div>

      <div>
        <img alt="Van carousel image" src="/van/main/VAN_Slider_Main_8.jpg" />
      </div>
    </Carousel>
  )
}

export default VanCarousel
